#MainPhoto {
	height: 50.7vw;
	background-image: url("../Assets/Photos/NationalsPhoto.jpg");
	background-attachment: fixed;
	background-position-y: 0;
	background-position-x: center;
	background-repeat: no-repeat;
	background-size: contain;
	padding: 0;
}
#MainPhoto img {
	width: 34vw;
	position: absolute;
	left: 50vw;
	transform: translate(-50%, -50%);

	top: 40vw;
	z-index: 1;
}
#MainPhoto::after {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 50.7vw;
	background-image: linear-gradient(
		to bottom,
		rgba(231, 233, 241, 0) 50%,
		rgba(231, 233, 241, 0.5),
		rgba(231, 233, 241, 1)
	);
	content: "";
}
