.award-container {
	display: grid;
	grid-template-rows: auto 5.65vw;
	grid-template-columns: 0;
	width: 18.2vw;
	margin-bottom: 3vw;
	.award-title {
		transition-duration: 0.3s;
		grid-row: 2;
		width: 28vw;
		background-color: rgba(255, 255, 255, 0.6);
		border-radius: 0 3vw 3vw 3vw;
		padding-top: 1.5vw;
		font-size: 2vw;
		text-align: center;
		backdrop-filter: blur(10px);
	}
	img {
		width: 18.2vw;
		height: 24.5vw;
		grid-row: 1/3;
	}
}
